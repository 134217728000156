import axios, { AxiosPromise } from 'axios';
import { IUserToken } from '../../../@types/model/right/userToken/userTokenModels';
import { IBaseUser } from '@zz2/zz2-ui';
import { IUserUpsert } from '../../../@types/model/right/user/userModels';

export default class UserHttpService {
    private static readonly PATH = '/User'

    public static get = async (userId : number) : Promise<IBaseUser> => {
        const result = await axios.get(`${API_URL}/v1/User/Get`, {
            params: {
                userId
            }
        });
        return result.data;
    }

    public static getList = async (signal ?: AbortSignal) : Promise<Array<IBaseUser>> => {
        const result = await axios.get(`${API_URL}/v1/User/GetList`, {
            signal
        });
        return result.data;
    }

    public static upsert = async (upsertData : Array<IUserUpsert>) : Promise<Array<IBaseUser>> => {
        const result = await axios.post(`${API_URL}/v1/User/Upsert`, upsertData);
        return result.data;
    }

    public static setEmployeeNumber = (employeeNumber : string) : AxiosPromise<IUserToken> => {
        return axios.post(`${API_URL}/v1/User/SetEmployeeNumber`, null, {
            params: {
                employeeNumber,
            },
        });
    }

    public static linkGoogleAccount = async (code : string) : Promise<IBaseUser> => {
        const result = await axios.post(`${API_URL}/v1/User/LinkGoogleAccountToUser`, {
            code
        });

        return result.data;
    }

    public static unlinkGoogleAccount = async () : Promise<IBaseUser> => {
        const result = await axios.post(`${API_URL}/v1/User/UnlinkGoogleAccountFromUser`);

        return result.data;
    }

    public static logout = () : AxiosPromise => {
        return axios.post(`${API_URL}/v1${this.PATH}/LogoutUser`);
    }

    public static logoutUsers = async (userIds : Array<number>) : Promise<void> => {
        await axios.post(`${API_URL}/v1${this.PATH}/LogoutUsers`, userIds);
    }

    public static logoutAllUsers = async () : Promise<void> => {
        await axios.post(`${API_URL}/v1${this.PATH}/LogoutAllUsers`);
    }

    public static requestForgottenPasswordEmail(email : string) : AxiosPromise<void> {
        return axios.post(`${API_URL}/v1${this.PATH}/RequestPasswordReset`, null, {
            params: {
                email,
            }
        });
    }

    public static resetPassword(oldPassword : string, newPassword : string, recoveryCode : string) : AxiosPromise<boolean> {
        return axios.post(`${API_URL}/v1${this.PATH}/ResetUserPassword`, null, {
            params: {
                oldPassword,
                newPassword,
                recoveryCode,
            }
        });
    }

    public static userDelete = (userId : number) : Promise<void> => {
        return axios.delete(`${API_URL}/v1${this.PATH}/Delete`, {
            params: { userId },
        });
    }
}
